import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import MenuBar from './menuBar';
import { FaUpload, FaRotateLeft, FaRotateRight, FaFileInvoice, FaCamera } from "react-icons/fa6";

function ScanQR() {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState("");
  const [preview, setPreview] = useState(null);
  const [rotate, setRotate] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleCameraOpen = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';
    input.className = 'hidden';
    input.onchange = handleFileChange;
    input.click();
  };

  const getRotatedImg = (image, rotation) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const width = image.naturalWidth;
    const height = image.naturalHeight;
    const radians = (rotation * Math.PI) / 180;
    const newWidth = Math.abs(Math.cos(radians) * width) + Math.abs(Math.sin(radians) * height);
    const newHeight = Math.abs(Math.sin(radians) * width) + Math.abs(Math.cos(radians) * height);
    
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.translate(newWidth / 2, newHeight / 2);
    ctx.rotate(radians);
    ctx.drawImage(image, -width / 2, -height / 2);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        resolve(blob);
      }, "image/jpeg", 1.0);
    });
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!preview) {
      alert("Please capture or upload an image first!");
      return;
    }

    setIsUploading(true);
    try {
      const rotatedBlob = await getRotatedImg(imageRef.current, rotate);
      const formData = new FormData();
      formData.append('image', rotatedBlob, 'invoice.jpg');

      const response = await fetch(`${process.env.REACT_APP_BUILDCOINS}rewards/upload-invoice`, {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      console.log(data)
      
      if (data) {
        alert(`Existing Invoice: ${data.data.existing_invoice}
          Type: ${data.data.type}
          Vendor: ${data.data.vendor}`);
                  setResponse(data.vendor || "Processing complete");
        setIsModalOpen(true);
      }else{
        alert('please provide authorised vendor')
      }
    } catch (error) {
      setResponse("Upload failed. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const rotateImage = (angle) => setRotate((prevRotate) => (prevRotate + angle) % 360);

  return (
    <div className="fixed inset-0 bg-gradient-to-br from-[#F85D5A] via-[#E54542] to-[#D12D2A]">
      <div className="h-screen flex flex-col">
        <div className="w-full">
          <MenuBar />
        </div>
  
        <div className="flex-1 overflow-y-auto px-4 pb-4 pt-2 flex items-center justify-center">
          <div className="w-[320px]">
            <div className="bg-white rounded-xl p-3 shadow-lg">
              <header className="flex items-center justify-center gap-2 mb-3">
                <FaFileInvoice className="text-xl text-blue-500" />
                <h1 className="text-lg font-bold text-gray-800">Invoice Upload</h1>
              </header>
  
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 gap-2">
                  <button
                    onClick={handleCameraOpen}
                    className="flex items-center justify-center gap-1 px-2 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 text-xs"
                  >
                    <FaCamera className="text-sm" /> Take Photo
                  </button>
  
                  <label className="flex items-center justify-center gap-1 px-2 py-1.5 bg-green-500 text-white rounded-lg text-xs cursor-pointer">
                    <FaUpload className="text-sm" /> Upload File
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="hidden"
                    />
                  </label>
                </div>
  
                {preview && (
                  <div className="space-y-2">
                    <div className="w-full h-[180px] flex items-center justify-center bg-gray-50 rounded-lg">
                      <img
                        src={preview}
                        alt="Preview"
                        ref={imageRef}
                        className="max-h-full max-w-full object-contain rounded-lg"
                        style={{
                          transform: `rotate(${rotate}deg)`,
                          transformOrigin: "center center",
                        }}
                      />
                    </div>
                    
                    <div className="flex justify-center gap-2">
                      <button
                        onClick={() => rotateImage(-90)}
                        className="flex items-center gap-1 px-2 py-1 bg-gray-500 text-white rounded-lg text-xs"
                      >
                        <FaRotateLeft className="text-sm" /> Left
                      </button>
                      <button
                        onClick={() => rotateImage(90)}
                        className="flex items-center gap-1 px-2 py-1 bg-gray-500 text-white rounded-lg text-xs"
                      >
                        <FaRotateRight className="text-sm" /> Right
                      </button>
                    </div>
  
                    <button
                      onClick={handleUpload}
                      disabled={isUploading}
                      className="w-full flex items-center justify-center gap-1 py-1.5 bg-red-500 text-white rounded-lg text-xs"
                    >
                      <FaFileInvoice className="text-sm" />
                      {isUploading ? 'Processing...' : 'Process Invoice'}
                    </button>
                  </div>
                )}
  
                {response && (
                  <div className="p-2 bg-gray-50 rounded-lg">
                    <h3 className="text-xs font-semibold text-gray-800 mb-1">Status</h3>
                    <p className="text-center text-green-600 text-xs">{response}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default ScanQR;
